// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon {
    margin-left: -28px;;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.grupo {
    display: inline !important;
    /* margin-right: 16px; */
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    /* background-clip: padding-box; */
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-group{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.btn-primary {
    color: #fff;
    background-color: #c00f10 !important;
    border-color: #d05758 !important;
}

.simple-footer {
    font-size: small;
}



`, "",{"version":3,"sources":["webpack://./src/assets/base/css/login.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,sBAAsB;AAC1B;;AAEA;IACI,0BAA0B;IAC1B,wBAAwB;IACxB,cAAc;IACd,WAAW;IACX,2BAA2B;IAC3B,yBAAyB;IACzB,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,sBAAsB;IACtB,kCAAkC;IAClC,yBAAyB;IACzB,sBAAsB;IACtB,qEAAqE;AACzE;;AAEA;IACI,aAAa;IACb,eAAe;IACf,2BAA2B;AAC/B;;AAEA;IACI,WAAW;IACX,oCAAoC;IACpC,gCAAgC;AACpC;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".icon {\n    margin-left: -28px;;\n    cursor: pointer;\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n}\n\n.grupo {\n    display: inline !important;\n    /* margin-right: 16px; */\n    display: block;\n    width: 100%;\n    height: calc(2.25rem + 2px);\n    padding: 0.375rem 0.75rem;\n    font-size: 1rem;\n    line-height: 1.5;\n    color: #495057;\n    background-color: #fff;\n    /* background-clip: padding-box; */\n    border: 1px solid #ced4da;\n    border-radius: 0.25rem;\n    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;\n}\n\n.form-group{\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: flex-start;\n}\n\n.btn-primary {\n    color: #fff;\n    background-color: #c00f10 !important;\n    border-color: #d05758 !important;\n}\n\n.simple-footer {\n    font-size: small;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
