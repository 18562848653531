import React from 'react';
import { Link } from "react-router-dom";

export const UserDropdown = (props) => {
    const { userDetail } = props;
    
    return (
        <>
            <div className="dropdown justify-content-end">
                <Link to="#" data-toggle="dropdown" className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    {/* <img src={userDetail.userImg}
                        alt=""
                        className="rounded-circle mr-1"
                    /> */}
                    <div className="d-sm-none d-lg-inline-block">
                        Hola, 
                    </div>
                </Link>
                <div className="dropdown-menu dropdown-menu-right">
                <div className="dropdown-title">
                    Estado: Activo
                </div>

                {/* {userDetail.datas.map((data, idata) => {
                        if(data.redirect){ 
                            // return (<Link key={idata} className="dropdown-item has-icon" to={data.link} >  <i className={data.icode} /> {data.title} </Link>) 
                            return (<a key={idata} href={data.link} className="dropdown-item has-icon" > <i className={data.icode} /> <span> {data.title} </span></a>) 
                        }else{
                            return (<NavLink
                                key={idata}
                                to={data.link}
                                className="dropdown-item has-icon"
                              >
                                <i className={data.icode} /> {data.title}
                              </NavLink>) 
                        }
                })} */}

                <div className="dropdown-divider" />
                <Link
                    to="#"
                    className="dropdown-item has-icon text-danger"
                    onClick={userDetail.logoutFunction}
                >
                    <i className={userDetail.logoutIcon} /> {userDetail.logoutTitle}
                </Link>
                </div>
            </div>

        </>
    );
};