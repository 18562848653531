import { LOGIN_SUCCESS, LOGIN_ERROR,  LOGOUT} from '../../config/types';

import { encode } from '../../config/crypto.js';


const AuthReducer = (state, action) => {
    switch(action.type){
        case LOGIN_SUCCESS:
            let obj = {
                isAuthenticated:true,
                token:action.payload.token,
                user: action.payload.user,
                // menu: action.payload.menu,
                message: action.payload.message 
            }
            localStorage.setItem('authInfo',encode(obj))
            return {
                ...state,
                token: action.payload.token,
                isAuthenticated: true,
                user: action.payload.user,
                menu: action.payload.menu,
                message: null
            }
        case LOGIN_ERROR:
            localStorage.clear();
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                user: null,
                // menu: null,
                message:action.payload
            }
        case LOGOUT:
            localStorage.clear();
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                user: null,
                // menu: null,
                message: null,
            }
        default:
             return state;
    }
}
export default AuthReducer;


