
import { motion } from "framer-motion"

import '../../assets/base/css/spinner/spinner.css';

const Spinner = () => 
<div className="loading">
    
    <motion.div 
           className="image ">
        <img id="img-spinner" src="/assets/images/logo.png" alt="" />
        <div className="spin spinner"></div>
    </motion.div>

</div>;

export default Spinner;