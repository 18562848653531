import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import "bootstrap/dist/css/bootstrap.min.css";
import './assets/base/css/bj_colors.css';
import './assets/base/css/components.css';
import './assets/base/css/style.scss';

import * as jQuery from "jquery";
// import * as CodeMirror from 'codemirror';

window.$ = window.jQuery = require('jquery')
window.jQuery = jQuery;
window.$ = jQuery;

require("jquery.nicescroll/dist/jquery.nicescroll.js");


require("sweetalert/dist/sweetalert.min.js");
require("popper.js/dist/popper.min");
require("jquery/dist/jquery.min");
require("jquery-ui-dist/jquery-ui.min.js");

require("bootstrap/dist/js/bootstrap.bundle.min.js");
require("./assets/base/js/js/stisla");
require("./assets/base/js/js/scripts");
require("./assets/base/js/js/custom");



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
