import React, { useContext } from 'react';
import './App.css';
import { LayoutMainRoutes } from './Routes/LayoutMainRoutes.js'
import AuthProvider  from '../src/contexts/auth/AuthState';
import  AuthContext  from '../src/contexts/auth/AuthContext.js';

function App() {

  const {isAuthenticated} = useContext(AuthContext)
  return (
    <AuthProvider value={{isAuthenticated}}>
      <LayoutMainRoutes/>
    </AuthProvider>
  );
}

export default App;
