import { Routes, Route } from 'react-router-dom';


import Page404 from '../components/base/page404';
import Users from '../components/users/index'

// const redirectIntranet = (Component) => {

//   window.location.replace(process.env.REACT_APP_MENU_URL)
      
// }
const AdminRouters = (

//  <BrowserRouter>
  <Routes>
    {/* <Route exact path='/' render={() => redirectIntranet(Home)}/> */}
    <Route exact path='/' element={<Users/>}/>
   
    <Route component={Page404}/>
    </Routes>
  // </BrowserRouter>
)

export {AdminRouters}

