import React ,{useEffect, useState, useRef} from 'react';
import { useParams } from 'react-router';
import { Link } from "react-router-dom";
import '../assets/base/css/style.scss';
import Call from '../config/Call'
import { useJwt } from "react-jwt";
import Swal from 'sweetalert2';


const NewPassword = (props)=>{
    
    const params = useParams() //GETTING PARAMS URL
    const [token, setToken] = useState(params.token);
    const { isExpired }  = useJwt(token)
    const [errorMessage, setErrorMessage ] = useState('')
    const countRef = useRef(0);
    
    const [password, setPassword] = useState(
        {
            password1:'',
            confirmPassword:''
        }
    )
    const [ error, setError ] = useState(false)

    useEffect(()=>{
        validateToken()
    }, [isExpired]) // eslint-disable-line react-hooks/exhaustive-deps

    const validateToken =()=>{
        if(isExpired){
            localStorage.clear();
        }
        // if(!localStorage.getItem('authInfo')){
        //     window.location.href='/login'
        // }
    }
    useEffect(()=>{
        countRef.current = isExpired;
    }, [isExpired.valueOf, countRef.current = isExpired]) // eslint-disable-line react-hooks/exhaustive-deps
    

    const handleChange=(e)=>{
        setPassword({
            ...password,
            [e.target.name]: e.target.value
        })
    }
    const changePassword=async()=>{

        const data={
            new_password: password.password1,
            confirm_password: password.confirmPassword
        }
        try{

            const res= await Call('POST', '/users/api/update_password/',true, data, false )
            
            if(res.data.code === 1){
                localStorage.clear();
                Swal.fire({
                    title: '',
                    text: res.data.message,
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Aceptar'
                  }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.href='/login'
                    }
                  })               

            }
        }catch(error){
            if(error.response.data.code === 'token_not_valid'){ 
                setToken('')
                return
            }else{
                Swal.fire({
                    title: '',
                    icon: 'error',
                    html: error.response.data.detail,
                    focusConfirm: false,
                    confirmButtonText:'Aceptar',
                })
            }
           
        }
    }
    const validatePassword =()=>{
        
        const isValid = (/^(?=.*[a-záéíóúñ])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^!¡&+=/*.-])[a-zA-Z0-9@#$%^¡!.&+=/*-ñ]{1,}$/).test(password.password1)
        
        if((password.password1.trim() === '') || (password.confirmPassword.trim() ==='')){
          setError(true)
          setErrorMessage("Debe llenar todos los campos")
          return
        }else if((password.password1.trim() !== '') && (password.confirmPassword.trim() !=='')){
            if(password.password1.trim() !== password.confirmPassword.trim() ){
                setError(true)
                setErrorMessage("Las contraseñas no son iguales")
                return
            }
            if(!isValid){
                setError(true)
                setErrorMessage("La contraseña no es válida, debe tener al menos un número, una mayúscula, una minúscula y un caracter especial")
                return
            }
            setError(false)
            changePassword()    
        }
    }

    return(
        <div>
            <div id="app">
                <section className="section">
                    <div className="container mt-5">
                        <div className="row text-center">
                            <div className="col-12 col-sm-8 col-md-6 col-lg-5 m-auto">
                                <img src="../../assets/images/cubo_rojo.ico" alt="logo" width="100" className="shadow-light rounded-circle my-4"/>
                                    <div className="card card-primary w-80 m-auto">
                                        <div className="card-header"><h4>Nueva contraseña</h4></div>
                                            <div className="card-body">
                                                
                                                {
                                                    !isExpired ?
                                                        <div className='text-start'>
                                                            <div id="passwordHelpBlock" className="f-italic form-text fs-12 mt-0 lh-1">
                                                                *Tu contraseña debe tener al menos un número, una mayúscula, una minúscula y un caracter especial
                                                            </div>
                                                            <label 
                                                                htmlFor="inputPassword5" 
                                                                className="form-label fs-12 mb-0 mt-4"
                                                            >
                                                                Contraseña
                                                            </label>
                                                            <input 
                                                                type="password" 
                                                                id="password1" 
                                                                className="form-control form-control-sm" 
                                                                // aria-describedby="passwordHelpBlock" 
                                                                name="password1"
                                                                value={password.password1}
                                                                onChange={handleChange}
                                                                
                                                            />
                                                            
                                                            <label 
                                                                htmlFor="inputPassword" 
                                                                className="form-label fs-12 mb-0 mt-3"
                                                            >
                                                                Confirmar contraseña
                                                            </label>
                                                            <input 
                                                                type="password" 
                                                                id="confirmPassword" 
                                                                className="form-control form-control-sm" 
                                                                // aria-describedby="passwordHelpBlock"
                                                                name="confirmPassword" 
                                                                value={password.confirmPassword}
                                                                onChange={handleChange}
                                                                
                                                            />
                                                            <div className='my3 lh-1'>
                                                            {
                                                                error && <span className='text-danger fs-12 lh-1'>{errorMessage}</span>
                                                            }
                                                            </div>
                                                            <div className="btn btn-primary w-100 my-4" onClick={validatePassword}>Cambiar contraseña</div>
                                                            

                                                        </div>
                                                        :
                                                        <div>
                                                            <i className="fas fa-times fs-30 text-danger mb-3"></i>
                                                            <div>Lo sentimos el tiempo se agotó, debes solicitar un nuevo código</div>
                                                            <div className='mt-4'>
                                                                <Link to="/login" className='text-danger'>Volver al inicio</Link>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>            
        </div>
    )

}
export default NewPassword