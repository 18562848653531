import React, {useContext } from 'react';
import '../../../assets/base/css/navbar/navbar.css';
import { UserDropdown } from './UserDropdown.js';
import { Link } from "react-router-dom";

import logo from '../../../assets/base/images/cubo_rojo.ico'; 

import AuthContext from '../../../contexts/auth/AuthContext';

export const NavBar = () => {
    
    const { logout, user } = useContext(AuthContext)
    
    const userDetail = {
        userName: user?user.apellidos_nombres:"JOHN",
        userImg: user?user.foto:logo,
        logoutIcon: "fas fa-sign-out-alt",
        logoutTitle: "Salir",
        logoutFunction:logout,
        datas: [{
            link: `${process.env.REACT_APP_MENU_URL}usuarios/perfil`,
            icode: "far fa-user",
            title: "Perfil",
            redirect:true
          },{
            link: `${process.env.REACT_APP_MENU_URL}password/change`,
            icode: "fas fa-bolt",
            title: "Cambiar Contraseña",
            redirect:true
            }
        ],
      };
    
    return (
        <>
              <div>
                <div className="navbar-bg" />
                <nav className="navbar navbar-expand-lg main-navbar justify-content-end">
                <form className="form-inline mr-auto">
                    <ul className="navbar-nav mr-3">
                    {/* <li>
                        <Link
                        to="#"
                        data-toggle="sidebar"
                        className="nav-link nav-link-lg"
                        >
                        <i className="fas fa-bars" />
                        </Link>
                    </li> */}

                    <li>
                        <Link
                        to="#"
                        data-toggle="search"
                        className="nav-link nav-link-lg d-sm-none"
                        >
                        <i className="fas fa-search" />
                        </Link>
                    </li>
                    </ul>
                    {/* <Search searchResultData={searchResultData} /> */}
                </form>
                <ul className="navbar-nav navbar-right">
                    {/* <ToggleData data={EnvelopData} /> */}
                    {/* {notificaciones && notificaciones.content.length>0?<ToggleData data={notificaciones} />:''} */}

                    <UserDropdown userDetail={userDetail} />                
                </ul>
                </nav>
            </div>
        </>
    );
};