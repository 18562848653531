import React, { useState, useEffect } from 'react';
import DataTableWeb from '../dataTable/indexWeb'
import DataTableCompMovil  from '../dataTable/indexMobile';
import Spinner from '../base/spiner';
import {TitleLayout} from '../base/TitleLayout'
import { Upload, Badge } from 'antd';
import Call from '../../config/Call'
import Swal from 'sweetalert2';

const Users =()=>{

    const [showSpinner, setShowSpinner ] = useState(false)
    const datat="Convenios"
    const [showModalUpload, setShowModalUpload ] = useState(false)
    const [ messageError, setMessageError ] = useState(null)
    const [dataf, setData] = useState({fileList:[]})
    const [options, setOPtions] = useState('create')
    const [ userList, setUserList ] = useState([])
    const [ userListComplete,setUserListComplete ] =useState([]) //USER LIST COMPLETE
    const [filterText, setFilterText ] = useState('')  // SEARCH TEXT IN DATATABLE
    
    useEffect(() => {

        getUserList();

      }, []);

      const getUserList= async()=>{

        try {
            setShowSpinner(true)
            const res= await Call('GET', '/customers/api/list_customers/', true, null, false)
            setUserList(res.data.data)
            setUserListComplete(res.data.data)
            setShowSpinner(false)

        } catch (error) {
            Swal.fire({
                title: '',
                icon: 'error',
                html: (error.response.data.message? error.response.data.message : 'Hubo un error contacte al administrador' ),
                focusConfirm: false,
                confirmButtonText:'Aceptar',
            })
        }
    }

    const columns = [
        {
            name: 'Nombre',
            selector: row => (row.first_name + ' ' +row.last_name),
        },
        {
            name: 'Tipo doc.',
            selector: row => row.document_type_display,
            width:'90px'
        },
        {
            name: 'Documento',
            selector: row => row.document,
            width:'150px'
        },
        {
            name: 'Cupo',
            selector: row => <span>${new Intl.NumberFormat().format(row.quota)}</span>,
            width:'100px'
        },
        {
            name: 'Cupo usado',
            selector: row => <span>${new Intl.NumberFormat().format(row.used_balance)}</span>,
        },
        {
            name: 'Cupo disponible',
            selector: row => <span>${new Intl.NumberFormat().format(row.available_balance)}</span>,
        },
        {
            name: 'Estado',
            cell: row => (
                <div className='text-start'>
                   { row.is_active? <Badge status="success" text="Activo"  size={"small"} />:  <Badge status="error"  text="Inactivo" />}
                </div>
            ),
            width:'100px'
        }
    ];
    const columnsM = [                                                                
        {
            name: 'Documento',
            selector: row => row.document,
            width:'130px'
        },
        {
            name: 'Estado',
            cell: row => (
                <div className='text-start'>
                   { row.is_active? <Badge status="success" text="Activo"  size={"small"} />:  <Badge status="error"  text="Inactivo" />}
                </div>
            ),
        }
    ];
    
    
    const onChangeImg = async ({ fileList }) =>{ 
        
        // if(((fileList[fileList.length-1] && fileList[fileList.length-1].size) && ((fileList[fileList.length-1].size/1024/1024))> 8)){
        //     message.error('El archivo debe ser menor a 8M');
        // }else 
        if(((fileList[fileList.length-1] && fileList[fileList.length-1].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') && (fileList[fileList.length-1] && fileList[fileList.length-1].type !=='application/vnd.ms-excel')) || (fileList[fileList.length-1] && fileList[fileList.length-1].type === '')){
            setMessageError('El formato del archivo no es permitido');
        }else{
            setMessageError(null)
            setData({ fileList })
            handle(fileList)
        }   
       
    }
    const beforeUpload = (file) => {
        return false;
    }
    const handle =async(e)=>{
       //UPLOAD FILE AND CHARGE IN ROUTE

        if(e.length !== 0 ){
            var file = e[0].originFileObj
            
            let formdata = new FormData() // CONVIRTING A FORM DATA
            formdata.append('clientes',file) // ADDING KEY AND FILE 
            try {
                setShowSpinner(true)
                const res= await Call('POST', `/customers/api/customers_load/${options}/`, true, formdata, true)
                setUserList(res.data.data) //UPDATING USER LIST
                setUserListComplete(res.data.data)
                Swal.fire({
                    title: '',
                    icon: 'success',
                    html: res.data.message,
                    focusConfirm: false,
                    confirmButtonText:'Aceptar',
                })
                setOPtions('create')
                setData({
                    ...dataf,
                    fileList:[]
                })
                setShowModalUpload(false)
                setShowSpinner(false)

            } catch (error) {
                setData({
                    ...dataf,
                    fileList:[]
                })
                setShowSpinner(false)
                    Swal.fire({
                    title: '',
                    icon: 'error',
                    html: (error.response.data.message? error.response.data.message : 'Hubo un error contacte al administrador' ),
                    focusConfirm: false,
                    confirmButtonText:'Aceptar',
                })
            }
           

        }
    } 

    //SUBHEADER SEARCH DATATABLE
    const handletext =(e)=>{
        
        setFilterText(e.target.value)
        let nuevo = (userListComplete.filter(item => item.document.includes(e.target.value)))
        setUserList(nuevo)
        if(e.target.value === ''){
            getUserList()
        }
    }
  
    const handleClear= ()=>{
        setFilterText('')
        getUserList()
    }

    const subHeaderComponentMemo2 = React.useMemo(() => {
        return (
            <>
            <input type="text" className="form-control form-control-sm w-30" 
                    placeholder="Buscar documento" 
                    aria-label="Buscar" 
                    aria-describedby="basic-addon1" 
                    onChange={handletext} 
                    value={filterText} 
                />
                { filterText !== '' &&
                    <div type="button" className='pl-2 cursor-pointer' onClick={handleClear} >
                        <i className="fas fa-times-circle"></i>
                    </div>
                }
    
            </>
        );
    
    });
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };  
    const expandedComponentDetalleConfirm=(row)=>{
        return(
            userList && userList.map((a, indexa)=>(
                   ((a.document === row.data.document) )&&
                    <div className='py-2 d-block text-start' key={indexa}>
                    <div><span className='fw-600'>Nombre: </span> {row.data.first_name + ' ' +row.data.last_name} </div>
                    <div><span className='fw-600'>Tipo doc: </span> {a.document_type_display} </div>
                    <div><span className='fw-600'>Cupo: </span>${new Intl.NumberFormat().format(a.quota)} </div>
                    <div><span className='fw-600'>Cupo usado: </span>${new Intl.NumberFormat().format(a.used_balance)} </div>
                    <div><span className='fw-600'>Cupo disponible: </span>${new Intl.NumberFormat().format(a.available_balance)} </div>
                </div>
            ))           
        )
    }   
    
    return(
        <div>
            { showSpinner && <Spinner />}
            <TitleLayout titulo={datat} clase={"text-start text-capitalize"} icon={true} seccion={'user'}  />
            {
                showModalUpload &&
                <>
                    <div className="modal modal-lg fade show d-block" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">Cargar usuarios</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>{setShowModalUpload(false); setOPtions('create')}}></button>
                            </div>
                            <div className="modal-body">
                                <section>
                                    <h6>Plantillas</h6>
                                    <span className='lh-1'>A continuación encontrarás los formatos para crear o eliminar usuarios</span>
                                    <div className='d-flex flex-wrap justify-content-around m-5'>
                                        <a 
                                            href="/assets/plantillas/creacion_de_usuarios.xlsx" 
                                            download 
                                            className='btn-sec my-2'
                                        >Crear usuarios</a>
                                        <a 
                                            href="/assets/plantillas/actualizar_usuarios.xlsx" 
                                            download 
                                            className='btn-sec my-2'
                                        >Actualizar usuarios</a>
                                        <a 
                                            href="/assets/plantillas/eliminar_usuarios.xlsx" 
                                            download 
                                            className='btn-sec my-2'
                                        >Eliminar usuarios</a>
                                    </div>
                                </section>
                               
                                <section className='text-center my-3'>
                                <div className='mb-4 fw-400'><span>Selecciona la acción que deseas ejecutar</span></div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" 
                                        type="radio" 
                                        name="inlineRadioOptions" 
                                        id="inlineRadio1" 
                                        value="create" 
                                        onChange={()=>{setOPtions('create')}}
                                        checked={options === 'create' ? true : false }
                                    />
                                    <label className="form-check-label fs-14" htmlFor="inlineRadio1">Crear usuario</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" 
                                            type="radio" 
                                            name="inlineRadioOptions" 
                                            id="inlineRadio2" 
                                            value="update" 
                                            onChange={()=>{setOPtions('update')}}
                                            checked={options === 'update' ? true : false }
                                        />
                                        <label className="form-check-label fs-14" for="inlineRadio2">Actualizar usuario</label>
                                    </div> 
                                    <div className="form-check form-check-inline">
                                    <input className="form-check-input" 
                                        type="radio" 
                                        name="inlineRadioOptions" 
                                        id="inlineRadio3" 
                                        value="delete" 
                                        onChange={()=>{setOPtions('delete')}}
                                        checked={options === 'delete' ? true : false }
                                    />
                                    <label className="form-check-label fs-14" htmlFor="inlineRadio3">Eliminar usuario</label>
                                    </div>
                                </section>
                                <section className='text-center my-5'>
                                    <Upload 
                                        accept=".xls, .xlsx"
                                        maxCount={1}
                                        fileList={dataf.fileList}
                                        onChange={onChangeImg}
                                        // iconRender={iconRender}  
                                        beforeUpload={beforeUpload} 
                                        className='uploadUsers'
                                    >
                                        <div className='btn-info-outline fs-16'><i className="fas fa-upload fs-16"></i> Cargar archivo</div>
                                    </Upload>
                                    { messageError && <span className='text-danger fs-12'>{messageError}</span>}
                                </section>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </>
            }
            <div className='bg-white col-lg-10 col-md-10 col-sm-12 mx-auto p-3 mb-5' style={{minHeight:'60vh', paddingBottom:'1rem'}}>

                <div className='my-4 text-center cursor-pointer btn-create mx-auto' 
                    onClick={()=>{setShowModalUpload(true)}}
                    data-toggle="modal" 
                    data-target="#exampleModal"
                >
                    Gestionar usuarios
                </div>
                <section className='w-90 text-center m-auto'>
                    <div className='ocultar-movil'>
                        <DataTableWeb 
                            data={userList && userList} 
                            columns={columns && columns}
                            subHeaderComponent={subHeaderComponentMemo2} 
                            paginationComponentOptions={paginationComponentOptions}
                            paginationPerPage={15}
                        />
                    </div>
                    <div className='mostrar-movil'>
                        <DataTableCompMovil
                            columns={columnsM && columnsM}
                            data={userList && userList} 
                            expandedComponent={expandedComponentDetalleConfirm} 
                            pagination={true}
                            paginationComponentOptions={paginationComponentOptions}
                            paginationPerPage={15}
                            subHeaderComponent={subHeaderComponentMemo2}
                         />
                    </div>
                </section>
            </div>
        </div>
    )
}
export default Users;