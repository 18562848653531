import React ,{useEffect, useState, useContext} from 'react';
import '../assets/base/css/style.scss';
import '../assets/base/css/login.css';
import Call from '../config/Call'
import Countdown, {zeroPad} from 'react-countdown';
import { encode } from '../config/crypto';
import Spinner from '../components/base/spiner'
import $ from 'jquery';

import {useForm} from '../components/formularios/useForm.js';

import AuthContext from '../contexts/auth/AuthContext';
import Swal from 'sweetalert2';

export const Login = (props) => {
    const [see, setSee] = useState(false)
    const [showSpinner, setShowSpinner ] = useState(false)

    const initialState = { 
        feedusername:false,
        feedpass:false
    }

    const [{username,password}, handleInputChange, handleInputClear] = useForm({
        username:'',
        password:''
    });
    const [ code, setCode] = useState({number:''})
    const [emailRecovery, setEmailRecovery ] = useState('')

    // const query = new URLSearchParams(props.location && props.location.search)
    // const next = query.get('next')
    
    const { loginFunction, isAuthenticated, message } = useContext(AuthContext)
   const [show, setShow] =useState(false)
    const [ validateCode, setValidateCode ]= useState(false)
    const [time, setTime] = useState('')
    useEffect(() => {        
        if(isAuthenticated){
            // console.log(next, 'next')
            // if (next==='/'){
            //     console.log('ca 1') 
            //     // window.location.replace(process.env.REACT_APP_MENU_URL)
            //     window.location.replace('/')
            // }else{
            //     console.log('ca 2') 
                window.location.replace('/')
            // }
            return
        }

        if(message === "este usuario no existe"){
            setFeeds({feedpass:false,feedusername:true})
        }else if (message === "contraseña incorrecta") {
            setFeeds({feedpass:true,feedusername:false})
        }else if (message === "nombre de usuario no existe o es incorrecto") {
            setFeeds({feedpass:false,feedusername:true})
        }else{
            
        }
    }, [isAuthenticated, message]); // eslint-disable-line react-hooks/exhaustive-deps


    const [feeds, setFeeds ] = useState(initialState);
    const [recoveryPassword, setRecoveryPassword ] = useState(false)

    const feedusername = feeds.feedusername
    const feedpass = feeds.feedpass

    const seePass = () =>
    {                

        setSee(!see)
        if(see)
        {            
            $('#id_password').attr('type', 'password')            
        } else {          
            $('#id_password').attr('type', 'text')            
        }
    }


    const handleSubmit =(e) =>{
        e.preventDefault();
        loginFunction({document:username, password})
    }
    //RECOVERY PASSWORD
    const [error, setError] = useState(null);

    const isValidEmail=(email)=> {
        return /\S+@\S+\.\S+/.test(email);
    }
    const handleChange =(e)=>{
        e.preventDefault();
            if (!isValidEmail(e.target.value)) {
                setError('Email is invalid');
            } else {
            setError(null);
            }
            setEmailRecovery(e.target.value)        
    }
    const sendEmail =async()=>{
        
        setShow(false)
        if((emailRecovery !=='') && (error === null)){
            try{
                setShowSpinner(true)
                const res= await Call('POST', '/users/api/send_code/', true, {"email": emailRecovery}, false)
               
                if(res.data.code === 1){
                    Swal.fire({
                        title: '',
                        icon: 'success',
                        html: res.data.message,
                        focusConfirm: false,
                        confirmButtonText:'Aceptar',
                    })
                    setTime(Date.now()+60000)
                    setValidateCode(true)
                    setRecoveryPassword(false)
                    setShow(true)
                    setShowSpinner(false)
                }else{
                    setShowSpinner(false)
                    Swal.fire({
                        title: '',
                        icon: 'error',
                        html: res.data.message,
                        focusConfirm: false,
                        confirmButtonText:'Aceptar',
                    })
                }

            }catch(error){
                setShowSpinner(false)
                Swal.fire({
                    title: '',
                    icon: 'error',
                    html: error.message,
                    focusConfirm: false,
                    confirmButtonText:'Aceptar',
                })
            }
        }

    }
    //VALIDATE CODE
    
    //   updateToken()
    const sendCode =async()=>{
       
        if(code.number !== ''){
            setShowSpinner(true)
            try {
                const res = await Call('POST', '/users/api/get_token_code/', true, {code: code.number}, false)
                if(res.data.code === 1){
                    window.location.href = `/cambiar-contrasena/${res.data.access_token}`
                    // setToken(res.data.access_token)
                    setShowSpinner(false)
                    let obj = {
                        isAuthenticated:true,
                        token:res.data.access_token,
                    }
                    localStorage.setItem('authInfo',encode(obj))
                }else{
                    setShow(true)
                    setShowSpinner(false)
                    setCode({number:''})
                    Swal.fire({
                        title: '',
                        icon: 'error',
                        html: res.data.message,
                        focusConfirm: false,
                        confirmButtonText:'Aceptar',
                    })
                    
                }
            } catch (error) {
                setShowSpinner(false)
                setCode({number:''})
                Swal.fire({
                    title: '',
                    icon: 'error',
                    html: error.response.data.message,
                    focusConfirm: false,
                    confirmButtonText:'Aceptar',
                })
            }
        }
    }
    //countdown
    
    // Random component
        const Completionist = () => <span className='fs-12 text-right text-danger cursor-pointer' onClick={sendEmail}>Reenviar código</span>;

        // Renderer callback with condition
        const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <Completionist />;
        } else {
            // Render a countdown
            return <span>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
        }
        };


    return (
        <>
            { showSpinner ? <Spinner /> :''}
            <div id="app">
            
                <section className="section">
                
                <div className="container mt-5">
                    <div className="row text-center">
                    <div className="col-12 col-sm-8 col-md-6 col-lg-5 m-auto">
                            <img src="../../assets/images/cubo_rojo.ico" alt="logo" width="100" className="shadow-light rounded-circle my-4"/>
                        <div className="card card-primary w-80 m-auto">
                        {   (!recoveryPassword && !validateCode) ?
                            <>
                                <div className="card-header"><h4>Login</h4></div>
                                <div className="card-body">
                                    <form onSubmit={handleSubmit} className="needs-validation" >
                                        <small className="text-danger">
                                        </small>
                                        <div className="form-group">
                                            <label className="control-label" htmlFor="id_username">Documento</label>
                                            <input tabIndex="1" autoComplete="off" value={username} onChange={ handleInputChange } type="text" name="username"  className={feedusername===false?'form-control grupo':'form-control grupo is-invalid'} placeholder="" id="id_username" maxLength="25" required/>
                                            <i className="icon fas fa-user" id="togglePassword"></i>
                                            <div className="invalid-feedback">
                                                Numero de documento no existe o no es valido
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="" className="control-label">Contraseña</label> 
                                            <input  tabIndex="2" autoComplete="on" className={feedpass===false?'form-control grupo':'form-control grupo is-invalid'} onChange={ handleInputChange } type="password" value={password} name="password" id="id_password" required/>
                                            <i className={`fa -see-pass ${(!see) ? 'icon fa-eye' : 'icon fa-eye-slash'}`} onClick={seePass} id="togglePassword"></i>
                                            <div className="invalid-feedback">
                                                Por favor ingresa o verifica tu contraseña
                                            </div>
                                        </div>
                                        <div className='d-flex w-100 justify-content-between'>
                                            <div className="form-group">
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" name="remember" className="custom-control-input" tabIndex="3" id="remember-me"/>
                                                    <label className="custom-control-label ms-2" htmlFor="remember-me"> Recuérdame</label>
                                                </div>
                                            </div>
                                            <div className='fs-12 text-right text-danger cursor-pointer vertical-sub' onClick={()=>{setRecoveryPassword(true); handleInputClear(); setEmailRecovery(''); setError(false)}} >
                                                <u>Recuperar contraseña</u>
                                            </div>
                                        </div>
                                        <div className="form-group text-center">
                                            <button type="submit" className="btn btn-primary w-100" tabIndex="4">
                                                Login
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </>
                            :
                            (   
                                ( !validateCode && recoveryPassword ) ? 
                                    <div className='p-2'>
                                            <div className="text-danger text-start">
                                                <i className="fas fa-long-arrow-alt-left fs-20 cursor-pointer vertical-sub" onClick={()=>{setRecoveryPassword(false); setShow(false); setValidateCode(false)}}></i>
                                                <span className='fw-600'> Recuperar contraseña</span>
                                                
                                            </div>
                                            <div className='mt-5'>
                                                <div className="mb-3 text-start">
                                                    <label htmlFor="exampleFormControlInput1" className="control-label fs-12 ">Correo electrónico</label>
                                                    <input 
                                                        type="email" 
                                                        className="form-control form-control-sm" 
                                                        id="exampleFormControlInput1" 
                                                        placeholder="name@example.com"
                                                        name="email"
                                                        onChange={handleChange} 
                                                        required
                                                    />
                                                    {error && <span className='text-danger fs-12 fst-italic text-start w-100'>El correo ingresado no es válido</span>}
                                                </div>
                                                <div className="form-group text-center mt-5">
                                                    <button type="submit" className={`btn btn-primary w-100 ${error === null ? '': 'cursor-not' }`} tabIndex="4" onClick={sendEmail}>
                                                        Enviar código
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                :
                                (
                                    (validateCode && !recoveryPassword) ?
                                        <div className='p-2'>
                                            <div className="text-danger text-start">
                                                <i className="fas fa-long-arrow-alt-left fs-20 cursor-pointer vertical-sub" onClick={()=>{setRecoveryPassword(false); setValidateCode(false); setCode('')}}></i>
                                                <span className='fw-600'> Validar código</span>
                                                
                                            </div>
                                            <div className='mt-5'>
                                                <div className="mb-3 text-start number">
                                                    <label htmlFor="exampleFormControlInput1" className="control-label fs-12 ">Código</label>
                                                    <input 
                                                        type="number" 
                                                        className="form-control form-control-sm" 
                                                        id="exampleFormControlInput1" 
                                                        placeholder=""
                                                        value={code.number}
                                                        name="code"
                                                        maxLength="6"
                                                        onChange={(e)=>{setCode({number: e.target.value})}} 
                                                    />
                                                </div>
                                                <div className='text-end'>
                                                    {show &&
                                                   
                                                        <Countdown
                                                            date={time}
                                                            renderer={renderer}
                                                            zeroPadTime={3}
                                                        />
                                                    }
                                                </div>
                                                <div className="form-group text-center mt-5">
                                                    <button type="submit" className={`btn btn-primary w-100 ${((error === null) && (code.number !== '')) ? '': 'cursor-not' }`} tabIndex="4" onClick={sendCode}>
                                                        Validar código
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        :''
                                ) 
                            )
                        }
                        
                        </div>
                        <div className="simple-footer">
                        Copyright &copy; Big Denim 2023
                        </div>
                    </div>
                    </div>
                </div>
                </section>
            </div>

            <script src="https://code.jquery.com/jquery-3.3.1.min.js" integrity="sha256-FgpCb/KJQlLNfOu91ta32o/NMZxltwRo8QtmkMRdAu8=" crossOrigin="anonymous"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js" integrity="sha384-UO2eT0CpHqdSJQ6hJty5KVphtPhzWj9WO1clHTMGa3JDZwrnQq4sF86dIHNDz0W1" crossOrigin="anonymous"></script>
            <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js" integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM" crossOrigin="anonymous"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.nicescroll/3.7.6/jquery.nicescroll.min.js"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.24.0/moment.min.js"></script>        
        </>
    );
};
