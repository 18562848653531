import React, {useEffect} from 'react';
import DataTable from 'react-data-table-component';



const  DataTableWeb =(props) => {

    useEffect(()=>{
        
    },[props.data,props.filterText])

    return (
        <DataTable
            pagination
            paginationComponentOptions={props.paginationComponentOptions&& props.paginationComponentOptions}
            subHeader
            paginationServer={props.paginationServer&& props.paginationServer}
            subHeaderComponent={props.subHeaderComponent&& props.subHeaderComponent}
            noDataComponent="En este momento no hay nada para mostrar"
            columns={props.columns&& props.columns}
            data={props.data&& props.data}
            paginationTotalRows={props.paginationTotalRows && props.paginationTotalRows}
            paginationPerPage={props.paginationPerPage && props.paginationPerPage}
            paginationRowsPerPageOptions={props.paginationRowsPerPageOptions&& props.paginationRowsPerPageOptions}
            // className="datosmovil"
            customStyles={props.customStyles && props.customStyles} 
            responsive={props.responsive&& props.responsive}
            onChangePage={props.onChangePage && props.onChangePage}
            conditionalRowStyles={props.conditionalRowStyles}
        />
    );
} 
export default DataTableWeb;