import React, {useState} from 'react';
import { Tooltip } from 'antd';

export const TitleLayout = (props) => {
    
    // const { data } = props;
    const [showInst, setShowInst] = useState(false)
    const [urlVideo, setUrlVideo] = useState(null)
    const aqui =()=>{
        if(props.seccion === 'user'){
            setUrlVideo('assets/instructivos/instructivo-b2b.mp4')
        }
        
        setShowInst(true)
    }
    
    return (
        <>
            <div className="section-header">
                <h1 className={`encabezado ${props.clase ? props.clase : ''}`}>{props.titulo && props.titulo}</h1>
                {
                    props.icon && 
                    <div>
                        <Tooltip color={'#aba7a7c2'} placement="left" title="Instructivo">
                            <span className="cursor-pointer text-danger d-flex fs-14" onClick={aqui}><i className="fas fa-info-circle fs-18 cursor-pointer text-danger mr-1"></i> Instructivo</span>
                        </Tooltip>
                    </div>
                }
                {
                    showInst &&
                    <>
                        <div className="modal show" style={{display:'block'}} id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg modal-dialog-centered">
                                    <div className="modal-content">
                                    <div className="modal-header">
                                        <h6 className="modal-title" id="staticBackdropLabel">Instructivo</h6>
                                        <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={()=>{setShowInst(false) }}>
                                        {/* <span aria-hidden="true">&times;</span> */}
                                        </button>
                                    </div>
                                    <div className="modal-body text-center">
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <iframe className="embed-responsive-item" src={urlVideo} allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div>
                    </>
                }
            </div>
        </>
    );
};