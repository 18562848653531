import React from 'react';

const Page404  =(props)=>{

    return(
        <div className='mt-4'>
            <div className='bg-white p-4 bg-form mt-5' style={{minHeight:'80vh', overflow:'auto'}}>
                <div className='text-center'>404</div>
            </div>
        </div>

    )
}

export default Page404;