import React, { useContext } from 'react';
import {BrowserRouter, Routes, Route, Navigate  } from 'react-router-dom';


// layout
import {LayoutMain}  from '../layouts/LayoutMain';
import { Login } from '../layouts/Login';
import NewPassword from '../layouts/newPassword'




// context
import AuthContext from '../contexts/auth/AuthContext';


const LayoutMainRoutes = (props) => {  
    const {isAuthenticated} = useContext(AuthContext)
   

    const requireAuthLayoutMain = (Component) => {
        if (isAuthenticated){
          return (Component)
        }else{
          // let route = "/login"
          // if(window.location.pathname!== ''){
          //   route = route+"?next="+window.location.pathname
          // }
          return(<Navigate to="/login" />)
        }
    }

   

  return (
    <BrowserRouter>
        <Routes>
          <Route  path='/login' element={<Login/>}/>  
          <Route  path='/cambiar-contrasena/:token' element={<NewPassword />}/>     
          <Route path='/' element={requireAuthLayoutMain(<LayoutMain/>)} />
        </Routes>
      </BrowserRouter>
  )
};

export {
  LayoutMainRoutes
}
